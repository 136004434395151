import React from 'react';
import Layout from 'components/Layout';
import PlainPage from 'components/PlainPage';

export default function Submitted(props) {
  const content =
    '<div style="padding: 120; flex: 1; textAlign: center; height: 100%;">' +
    //"<div style={{padding: 120, flex: 1, textAlign: 'center', height: '100%',}}>" +
    '<h1>Thanks for getting in touch!</h1>' +
    "<p>We'll get back to you as soon as we can.</p>" +
    '<p>' +
    'Head back to ' +
    '<a href="\\" style="text-decoration: underline;">' +
    'our homepage' +
    '</a>' +
    '</p>' +
    '<img src="/images/notfound.png" alt="" style="max-height: 450;" />' +
    '</div>';

  return (
    <Layout>
      <PlainPage content={content} />
    </Layout>
  );
}
